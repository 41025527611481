@mixin btnColor(
	$bgColor,
	$textColor: $color-white,
	$hollowColor: false,
	$hoverBg: false,
	$hoverColor: false,
	$borderColor: false,
) {
	@if $hollowColor == false {
		$hollowColor: $bgColor;
	}

	@if $borderColor == false {
		$borderColor: $bgColor
	}

	background-color: $bgColor;
	border-color: $borderColor;
	color: $textColor;

	&:not(.no-hover):not(:disabled) {
		@include mediaMin {
			&:hover {
				@if $hoverBg == false {
					background-color: lighten($bgColor, $darken-ratio);
					border-color: lighten($bgColor, $darken-ratio);
				}
				@else {
					background-color: $hoverBg;
					border-color: $hoverBg;
				}

				@if $hoverColor == false {
					color: $textColor;
				}
				@else {
					color: $hoverColor;
				}
			}
		}

		&:active {
			@if $hoverBg == false {
				background-color: lighten($bgColor, $darken-ratio);
				border-color: lighten($bgColor, $darken-ratio);
			}
			@else {
				background-color: $hoverBg;
				border-color: $hoverBg;
			}
			
			@if $hoverColor == false {
				color: $textColor;
			}
			@else {
				color: $hoverColor;
			}
		}
	}

	&.outline {
		background-color: transparent;
		color: $hollowColor;

		&:not(.no-hover):not(:disabled) {
			@include mediaMin {
				&:hover {
					background-color: $bgColor;
					border-color: $bgColor;
					color: $textColor;
				}
			}

			&:active, &:focus {
				@if $hoverBg == false {
					background-color: lighten($bgColor, $darken-ratio);
					border-color: lighten($bgColor, $darken-ratio);
				}
				@else {
					background-color: $hoverBg;
					border-color: $hoverBg;
				}

				@if $hoverColor == false {
					color: $textColor;
				}
				@else {
					color: $hoverColor;
				}
			}
		}
	}
}

.btn {
	@include font-regular;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	box-shadow: $shadow-input;
	//cursor: pointer;
	
	font-size: 1.4rem;
	height: 4rem;
	line-height: 1;
	padding: 0 .9rem 0;
	border: .1rem solid $color-gray;
	border-radius: $radius-general;

	@include mediaMin {
		transition: background-color $transition-hover ease, color $transition-hover ease, border-color $transition-hover ease;
	}

	@include media {
		transition: background-color $transition-superfast ease, color $transition-superfast ease, border-color $transition-superfast ease;
	}

	// Sizes & Shapes

	&.block {
		width: 100%;
		display: flex;

		.btn-content {
			justify-content: center;
		}
	}

	&.wide {
		//min-width: 26.5rem;
		padding-left: 2rem;
		padding-right: 2rem;

		&.small {
			padding-left: 1.8rem;
			padding-right: 1.8rem;
		}
	}

	// &.narrow {
	// 	//min-width: 26.5rem;
	// 	padding-left: 1.8rem;
	// 	padding-right: 1.8rem;

	// 	&.small {
	// 		padding-left: .8rem;
	// 		padding-right: .8rem;
	// 	}
	// }

	&.big {
		//@include font-medium;
		padding: .6rem 1.6rem;
		height: 5.2rem;

		&.rounded {
			border-radius: 2.5rem;
		}

		&.small-icon-pre {
			.btn-icon {
				&.pre {
					font-size: 1rem;
					margin-right: .8rem;
				}
			}
		}

		.btn-icon {
			&.pre {
				font-size: 1.7rem;
				margin-right: 1.1rem;
			}
		}
	}

	&.small {
		height: 3.6rem;
		padding: 0 1.2rem;
		//@include font-regular;

		&.rounded {
			border-radius: 1.5rem;
		}

		.btn-icon {

			&:not(.post) {
				font-size: 1.4rem;
			}

			&.pre {
				margin-right: 1rem;
				padding-top: 0;
			}

			&.post {
				font-size: 1.2rem;
				margin-left: 1rem;
				padding-top: 0;
			}
		}

		&.small-icon-pre {
			.btn-icon {
				&:not(.post) {
					font-size: 1rem;
				}

				&.pre {
					margin-right: .8rem;
				}
			}
		}
	}

	&.low {
		height: 3rem;
	}

	&.text-small {
		font-size: 1.4rem;
	}

	&.small-icon-pre {
		.btn-icon {
			&.pre {
				font-size: .5rem;
				margin-right: .3rem;
				padding-top: .1em;
				margin-left: -.3rem;
			}
		}
	}
	&.big-icon-pre {
		.btn-icon {
			&.pre {
				font-size: 1.6rem;
				margin-right: .9rem;
				// padding-top: .1em;
				// margin-left: -.3rem;
			}
		}
	}

	&.big-icon {
		.btn-icon {
			&:not(.pre):not(.post) {
				font-size: 1.6rem;
			}
		}
	}

	&.rounded {
		border-radius: 1.9rem;
	}

	&.plain {
		@include btnColor(transparent, $color-text);
		padding: 0;
		box-shadow: none;
		height: auto;
		font-weight: inherit;
	}

	// Statusses

	&.show-status {
		.btn-content {
			opacity: 0;
			transform: scale(.9);
		}

		.btn-statuswrap {
			opacity: 1;
			transform: scale(1);
		}
	}

	// Colors

	@include btnColor($color-text, $color-white);

	&.green {
		@include btnColor($color-green, $color-white);
	}

	&.blue {
		@include btnColor($color-blue, $color-white);
	}

	&.blue-bg {
		@include btnColor($color-blue-bg, $color-blue-light, false, $color-blue-light, $color-white, $color-blue-light);
	}

	&.error {
		@include btnColor($color-error, $color-white);

		&.plain {
			@include btnColor(transparent, $color-error);
		}
	}

	&.success {
		@include btnColor($color-success, $color-text);
	}

	&.nude {
		box-shadow: none;
		@include btnColor(transparent, $color-text);
	}

	&.underline {
		.btn-content {
			text-decoration: underline;
		}
	}

	&.yellow {
		@include btnColor($color-yellow);
	}

	&.white {
		@include btnColor($color-white, $color-text, false, $color-gray, false, $color-gray);
	}

	&.gray {
		@include btnColor($color-gray, $color-text, $color-text);
	}

	// Inner Components

	.btn-content {
		display: flex;
		// flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		transition: opacity $transition-hover ease, transform $transition-hover ease;
	}

	.btn-statuswrap {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transition: opacity $transition-hover ease, transform $transition-hover ease;
		transform: scale(1);
		transform: translate3d(0, 0, 0);

		.icon-spinner {
			@include spin();
		}

		.btn-status {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
		}
	}
	
	.btn-icon {
		&.pre {
			font-size: 1rem;
			margin-right: .5rem;
			margin-top: -.1em;
		}

		&.post {
			margin-left: 1.2rem;
			font-size: 1rem;
			padding-top: .1em;
		}
	}

	// States

	&:disabled:not(.status-loading) {
		opacity: 0.5
	}
}