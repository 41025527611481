.datepicker {
	position: relative;
	
	.datepicker-button {
		display: block;
		width: 100%;
		//height: 5.2rem;
	}

		.button-placeholder {
			color: rgba($color-text, .25);
		}

	.datepicker-calendarwrap {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 3;
		//max-width: 100%;
		
	}

	.rdrCalendarWrapper {
		font-size: 1.2rem;
		color: $color-text;
		border: .1rem solid $color-gray;
		border-radius: $radius-general;
		box-shadow: $shadow-general;

		.rdrMonth {
			width: 30rem;
		}

		.rdrDayDisabled {
			background: rgba($color-bg, .3);
		}

		.rdrDayToday {
			.rdrDayNumber {
				top: .5rem;
				bottom: .5rem;

				span {
					&:after {
						background: $color-text;
						width: 1.8rem;
						height: .2rem;
						bottom: .4rem;
					}
				}
			}
		}
	}
}