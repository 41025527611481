.modal-order-payment-link {

	.modal-contentwrap {
		width: 40rem;
	}

	.modal-content {
		@include media {
			padding-bottom: 0;
		}
	}

	.link-form {
		display: flex;
		flex-flow: row wrap;
	}
	
	.form-input {
		margin-right: 1rem;
		
		&.target {
			flex: 1 0 0;
		}

		&.language {
			width: 7rem;
		}
	}

		.link-closebtn {
			display: block;
			width: 100%;
			font-size: 1.2rem;
			border-top: .1rem solid $color-gray;
			text-align: center;
			//color: $color-text-light;
			padding: 1rem 0 1.5rem;
			margin-top: 1rem;

			i {
				font-size: .8rem;
				margin-right: .5rem;
			}
		}
}