.modal-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50;
	// overflow: hidden;
	outline: none;
	backface-visibility: hidden;
	overflow: auto;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	&.mobile-full {
		@include media {
			.modal-contentwrap {
				max-width: 100%;
				margin: 0;
				padding: 0;
				min-height: 100%;
				display: flex;
				flex-flow: column wrap;
				transform: translate3d(0, 3rem, 0);
				background-color: $color-white;
			}

			.modal-innerwrap {
				flex: 1 0 0;
			}

			.modal-header {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				//padding-top: 6.5rem;
			}

			.modal-closebtn {
				top: 1.5rem;
				right: 1.5rem;
				bottom: auto;
				color: $color-text;
			}

			.modal-content {
				border-top-left-radius: 0;
				border-top-right-radius: 0;

				&:last-child {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
				flex: 1 0 0;

				&.dark {
					+ .modal-controls {
						padding-top: 1.5rem;
					}
				}
			}

			.modal-controls {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;

				padding-bottom: 1.5rem;
			}
		}
	}

	&.top-level {
		z-index: 52;
	}

	&.modal-from-bottom {
		@include media {
			// .modal-innerwrap {
			// 	// min-height: 100vh;
			// 	// justify-content: flex-end;
			// }

			.modal-contentwrap {
				// margin-bottom: 0;
				// max-width: none;
				transform: translate3d(0, 1.5rem, 0);
				width: 100%;
				position: absolute;
				padding-bottom: 0;
				bottom: 0;
				max-width: none;
			}

			.modal-content {
				&:last-child {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
	}

	.modal-outerwrap {
		position: absolute;
		z-index: 50;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		// display: flex;
		// flex-direction: column;
		// justify-content: center;
		// flex-wrap: wrap;

		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
	}

	.modal-innerwrap {
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		height: 100%;
	}

	.modal-contentwrap {
		position: relative;
		width: 70rem;
		opacity: 0;
		z-index: 2;
		transform: translate3d(0, -.5rem, 0);
		transition: opacity $transition-anim ease, transform $transition-anim-fast ease;
		margin: 0 auto;
		padding: calc(8vh + 2rem) 0 calc(8vh + 2rem);
		max-width: calc(100% - 3rem);
		box-shadow: $shadow-general;
		border-radius: $radius-big;
		-webkit-backface-visibility: hidden;

		> .loader-container {
			.loading-indicator {
				border-radius: $radius-general;
			}
		}

		> .tabs-container {
			background-color: $color-white;
			border-bottom-left-radius: $radius-big;
			border-bottom-right-radius: $radius-big;

			&:first-child {
				border-top-left-radius: $radius-big;
				border-top-right-radius: $radius-big;
			}

			.tabs-labels {
				background-color: $color-bg;
			}

			.items-tab {
				padding-top: 0;
			}
		}

		> .modal-header {
			+ .tabs-container {
				// .labels-item {}

					.item-btn {
						padding-top: 1rem;
					}
			}
		}

		@include media {
			margin: 4rem auto 5rem;
		}
	}

	// Sections
	.modal-header {
		@include font-medium;
		position: relative;
		z-index: 2;
		background-color: $color-white;
		padding: 3.5rem 2rem 2rem;
		border-top-left-radius: $radius-big;
		border-top-right-radius: $radius-big;

		&.dark {
			background-color: $color-bg;
		}

		+ .modal-content, + * > .modal-content:first-child {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.header-label {
			position: absolute;
			top: 1rem;
			left: 2rem;
			// transform: translate3d(0, -50%, 0);
		}

		.header-title {
			@include font-bold;
			font-size: 1.6rem;

			@include media {
				font-size: 1.4rem;
			}

			i {
				font-size: 1.6em;
				margin-right: .6em;
				margin-top: -.2em;
			}

			.label {
				margin-left: .6rem;
			}
		}

		&.controls {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.header-controls {
			> * + * {
				margin-left: 1rem;
			}
		}
	}

	.modal-content {
		//box-shadow: $shadow-general;
		width: 100%;
		padding: 2.3rem 2rem;
		background-color: $color-white;
		border-top-left-radius: $radius-big;
		border-top-right-radius: $radius-big;
		position: relative;
		z-index: 1;

		&.dark {
			background-color: $color-bg;

			+ .modal-controls {
				padding-top: 2.3rem;
			}
		}

		&:last-child {
			border-bottom-left-radius: $radius-big;
			border-bottom-right-radius: $radius-big;
		}
	}

	.modal-controls {
		background-color: $color-white;
		border-bottom-left-radius: $radius-big;
		border-bottom-right-radius: $radius-big;
		padding: 0 2rem 2.3rem;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;

		&.dark {
			background-color: $color-bg;
			padding-top: 2.3rem;
		}

		> * {
			&.flex {
				flex: 1 0 0;
			}
		}

		&:not(.justify) {
			> * + * {
				margin-left: 1rem;
			}
		}

		&.justify {
			justify-content: space-between;
		}
	}
	
	.modal-closebtn {
		position: absolute;
		z-index: 50;
		bottom: calc(100% + 1.4rem);
		right: 0;
		color: $color-white;
		width: 1.5rem;
		height: 1.5rem;
		font-size: 1.5rem;
		border-radius: 50%;
		transition: color $transition-hover ease, background-color $transition-hover ease;
		display: flex;
		align-items: center;
		justify-content: center;

		@include media {
			&:active {
				color: $color-blue;
			}
		}

		@include mediaMin {
			&:hover, &:active {
				color: $color-blue;
			}	
		}
	}

	.modal-group {
		position: relative;
		z-index: 1;

		&.wide {
			width: calc(100% + 4.0rem);
			margin-left: -2.0rem;
			margin-right: -2.0rem;
			padding-left: 2rem;
			padding-right: 2rem;
		}

		$tot: 5;
		@for $i from 1 through $tot {
			&:nth-child(#{$i}) {
				z-index: $tot + 1 - $i;
			}
		}

		+ .modal-group {
			margin-top: 3rem;
		}

		.collapser-content {
			.collapser-innerwrap {
				padding-top: 1rem;
			}
		}

		&.dark {
			.collapser-content {
				background-color: $color-bg;

				.collapser-innerwrap {
					padding-top: 2rem;
				}
			}
		}
	}

	.modal-filters {
		margin: 0 -2rem 2rem;
		width: calc(100% + 4rem);

		&:first-child {
			margin-top: -2rem;
		}

		// @include media {
		// 	width: 100vw;
		// }

		&.dark {
			background-color: $color-bg;
		}

		.filters-innerwrap {
			padding: 2rem;

			@include media {
				white-space: nowrap;
				overflow: hidden;
				overflow-x: scroll;
			}

			> * + * {
				margin-left: 1.5rem;
			}
		}
	}

	.modal-tablewrap {
		width: calc(100% + 4.0rem);
		margin-left: -2.0rem;
		margin-right: -2.0rem;
	}

	&.show {
		.modal-overlay {
			opacity: 1;
			transition: opacity $transition-anim-fast ease;
		}

		.modal-contentwrap {
			opacity: 1;
			transform: translateY(0);
			transition: opacity $transition-anim-fast ease $transition-hover, transform $transition-anim-fast ease $transition-hover;
		}
	}

	&.narrow {
		.modal-contentwrap {
			width: 50rem;
		}
	}
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color-black,.6);
	opacity: 0;
	transition: opacity 300ms ease 200ms;
	z-index: 49;

	&.top-level {
		z-index: 51;
	}

	&.show {
		opacity: 1;
		transition: opacity $transition-anim-fast ease;
	}
}