.table-container {
	color: $color-text;
	width: 100%;
	background-color: $color-white;
	line-height: 1.3em;

	&.paginating {
		table {
			opacity: .5;
		}
	}

	&:not(.mobile-state) {
		table {
			tr {
				+ tr {
					td {
						border-top: .1rem solid $color-gray-light;
					}
				}
			}

			td, th {
				padding: 1.5rem 1rem;
				&:first-child {
					padding-left: 2rem;
				}

				&:last-child {
					padding-right: 2rem;
					text-align: right;
				}
			}
		}
	}

	&.mobile-state {
		table {
			display: block;
			padding-left: 2rem;
			padding-right: 2rem;

			tbody {
				display: block;
				width: 100%;
			}
		}

		tr {
			display: flex;
			flex-flow: row wrap;
			border: .1rem solid $color-gray;
			border-radius: $radius-big;

			+ tr {
				margin-top: 1.5rem;
			}

			.row-mobiletitle {
				@include font-bold;
				width: 100%;
				padding: 1.8rem 1rem .5rem;
				font-size: 1.6rem;
			}
		}

		td {
			display: block;
			width: 50%;
			padding: 1rem;

			.col-mobiletitle {
				display: block;
				color: $color-text-light;
				font-size: 1.2rem;
				margin-bottom: .5rem;
			}

			&.controls {
				text-align: right;
				border-top: .1rem solid $color-gray;
				width: 100%;
				margin-top: 1rem;
			}

			&.mobile-full {
				width: 100%;
			}

			&.mobile-flex {
				width: auto;
				flex: 1 0 0;
			}

			&.mobile-free {
				width: auto;
			}
		}
	}

	table {
		width: 100%;

		.table-heading {

			th {
				@include font-bold;
				text-align: left;
				border-bottom: .1rem solid $color-gray;
			}
		}

		td {
			vertical-align: top;

			&.al-left {
				text-align: left!important;
			}
			&.al-right {
				text-align: right!important;
			}
			&.al-center {
				text-align: center!important;
			}

			&.val-center {
				vertical-align: middle;
			}

			&.controls {
				> * {
					float: right;
					+ * {
						margin-top: 0;
						margin-right: .7rem;
					}
				}
			}

			>  * {
				+ * {
					margin-top: .6rem;
				}
			}
		}

		th {
			@include font-medium;
			text-transform: uppercase;
			font-size: 1.1rem;
		}
	}

	.table-emptymessage {
		margin: 2rem 2rem;
	}

	.table-minidata {
		strong {
			display: block;
			color: $color-text-light;
			font-size: 1.2rem;
			margin-bottom: .5rem;
		}
	}

	.table-subtitle {
		color: $color-text-light;
		font-size: 1.2rem;
	}

	.table-text-light {
		color: $color-text-light;
	}

	.table-imagewrap {
		width: 4rem;
		height: 4rem;
		border: .2rem solid $color-gray;
		border-radius: $radius-general;
		overflow: hidden;

		.image {
			width: 100%;
			height: 100%;
		}

		@include media {
			width: 7rem;
			height: 7rem;
		}
	}

	.table-pagination {
		text-align: right;
		width: 100%;
		padding: 1rem 2rem 0;

		&.loading {
			.pagination-display {
				@include icon('spinner', 'before');

				&:before {
					@include spin;
					position: absolute;
					left: 50%;
					top: 50%;
					color: $color-blue;
					z-index: 3;
				}

				&:after {
					@include pseudo;
					background-color: $color-white;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 2;
					border-radius: $radius-general;
				}
			}

			.pagination-list {
				opacity: 0;
			}
		}

		.pagination-content {
			display: inline-flex;
			align-items: center;
		}

		.pagination-btn, .pagination-display {
			background-color: $color-white;
			border: .1rem solid $color-gray;
			box-shadow: $shadow-input;
			border-radius: $radius-general;
			height: 2rem;
			min-width: 3rem;
			height: 3rem;
			padding: 0 1rem;
			//font-size: 1.2rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.pagination-btn {
			font-size: 1.1rem;
			&:disabled {
				opacity: .5;
			}
		}

		.pagination-display {
			cursor: pointer;
			margin: 0 1rem;
			position: relative;
		}

		.pagination-list {
			position: absolute;
			opacity: 0;
			left: 0;
			top: 0;
			height: 100%;
			z-index: 1;
			width: 100%;
		}
	}

	// @include media {
	// }
		
}