@mixin labelColors {
	&.light {
		background-color: $color-bg;
		color: $color-text;
	}
	&.dark {
		background-color: $color-text;
		color: $color-white;
	}
	&.success {
		background-color: $color-success;
		color: $color-white;
	}
	&.warning{
		background-color: $color-warning;
		color: $color-white;
	}
	&.error {
		background-color: $color-error;
		color: $color-white;
	}
	&.info{
		background-color: $color-blue-light;
		color: $color-white;
	}
	&.white {
		background-color: $color-white;
		color: $color-text;
	}
	&.orange {
		background-color: $color-orange;
		color: $color-white;
	}
	&.yellow {
		background-color: $color-yellow;
		color: $color-white;
	}
}

.label {
	padding: .3rem .5rem .2rem;
	font-size: 1.1rem;
	line-height: 1;
	text-shadow: none;
	vertical-align: middle;
	background-color: $color-info;
	color: #fff;
	font-weight: 600;
	border-radius: .25em;
	text-transform: uppercase;
	display: inline-block;
	margin-top: -.2em;

	+ .label {
		margin-left: .5em;
	}

	&.small {
		padding: .2rem .4rem;
	}
	&.big {
		font-size: 1.2rem;
		padding: .5rem 1.1rem .4rem;
	}

	&.text-medium {
		@include font-medium;
	}

	&.text-regular {
		@include font-regular;
	}

	*[class^="icon-"],
	*[class*=" icon-"] {
		font-size: .7rem;
		margin-top: -.1em;
		display: inline-block;
		vertical-align: middle;
	}

	@include labelColors;

	&.category-template {
		background-color: $color-category-template;
		color: $color-white;
	}

	&.category-commerce {
		background-color: $color-category-commerce;
		color: $color-white;
	}
}