.chart-container {
	height: 3rem;
	font-size: 1.2rem;

	.custom-tooltip {
		background-color: $color-white;
		border: .1rem solid $color-gray;
		min-width: 20rem;
		padding: 1rem;
		border-radius: $radius-general;
		box-shadow: $shadow-input;
	}

	.tooltip-title {
		@include font-medium;
		color: $color-text-light;
		text-transform: uppercase;
		display: block;
		margin-bottom: 1rem;
		text-align: center;
		font-size: 1rem;
	}

	.tooltip-group {
		display: flex;
	}

		.group-title {
			@include font-medium;
			color: $color-text-light;
			width: 15rem;
			font-size: 1.1rem;
			text-transform: uppercase;
		}

		.group-value {
			@include font-medium;
		}
}