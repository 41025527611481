//colors
$color-black: #000;
$color-white: #fff;

$color-gray-dark: #d9d9d9;
$color-gray: #eaecee;
$color-gray-light: #f7f7f7;

$color-red: #ff3824;
$color-red-dark: #9e1b4a ;

$color-green: #3db662;
$color-green-light: #00c1a0;

$color-yellow: #ffbd2f;
$color-yellow-dark: #d2a507;
$color-yellow-text: #e7cd76;
$color-yellow-bg: #fcf6e5;
$color-yellow-bg-light: #fff8d6;
$color-yellow-border: #e9db94;
$color-yellow-border-light: #ebe5c5;

$color-orange: #eb7419;
$color-orange-light: #fe9127;
$color-orange-dark: #fe7854;

$color-tan: #c3aeaa;
$color-tan-bg: #f3f2eb;

$color-blue: #0035ae;
$color-blue-dark: #032d8d;
$color-blue-light: #009fe3;
$color-blue-bg: #ecf8fd;

$color-purple: #6847a0;

// Text
$color-text: #3e475f; // $color-blue-dark;
$color-text-light: #7f9bb5; //#9ea3af;
$color-text-lighter: #b2c3d2;

$color-autofill: rgb(232, 240, 254);

// BG
$color-bg: #f6f6f4;
$color-bg-light: $color-gray-light;

// Categories
$color-category-template: #8B12BE;
$color-category-commerce: $color-yellow-dark;

// Status Colors
$color-success: $color-green-light;
$color-success-bg: lighten($color-green, 39%);
$color-success-border: lighten($color-green, 32%);
$color-success-text: darken($color-green, 10%);

$color-warning: $color-orange;
$color-warning-bg: lighten($color-orange, 45%);
$color-warning-border: lighten($color-orange, 35%);
$color-warning-text: darken($color-orange, 10%);

$color-error: $color-red;
$color-error-bg: lighten($color-red, 39%);
$color-error-border: lighten($color-red, 32%);
$color-error-text: $color-red-dark;

$color-info: #9ea2af;

//radiusses
$radius-general: .4rem;
$radius-big: .8rem;
$radius-bigger: 1.3rem;

//transition
$transition-superfast: 50ms;
$transition-fast: 100ms;
$transition-hover: 220ms;
$transition-anim-fast: 300ms;
$transition-anim: 400ms;
$transition-long: 600ms;

// darken
$darken-ratio-low: 4%;
$darken-ratio: 6%;

//easing
$ease-1: #{"cubic-bezier(0.32, 0.28, 0, 0.97)"};

//shadows
$shadow-general: #{"0 .3rem .4rem 0 rgba(0, 0, 0, 0.05)"};
//$shadow-shallow: #{".0rem .1rem .2rem .0rem rgba(0, 0, 0, 0.1)"};
$shadow-input: #{"0 .2rem .2rem 0 rgba(2, 32, 101, 0.1)"};
$shadow-big: #{"0 0 1.5rem 0.2rem rgba(2, 32, 101, 0.15)"};

// Theme Colors

// Deep Yellow
$color-theme2-orange: #ffc400;
$color-theme2-orange-dark: #ef6223;
$color-theme2-red: #ee3221;