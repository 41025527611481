.image {
	transition: opacity $transition-hover ease;

	&.image-loading {
		opacity: 0;
	}

	&.bg {
		.imagewrap-image {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		&.contain {
			.imagewrap-image {
				background-size: contain;
			}
		}

		&.abs {
			position: relative;

			.imagewrap-image {
				position: absolute;
			}
		}
	}
}

.image-box {
	border: .1rem solid $color-gray;
	border-radius: $radius-big;
	padding: .5rem;
	max-width: 100%;

	&.size-small {
		width: 10rem;
	}
}