.mapelement {
	height: 50rem;
	overflow: hidden;

	.mapelement-marker {
		position: relative;
		width: 4rem;
		height: 4rem;
		z-index: 2;
		transform: translate3d(-50%, -50%, 0);

		&.infobar-open {
			z-index: 3;
		}
	}

		.marker-btn {
			background-color: $color-blue;
			width: 100%;
			height: 100%;
			font-size: 2rem;
			color: $color-white;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.marker-infobar {
			position: absolute;
			font-size: 1.4rem;
			line-height: 1.2em;
			background-color: $color-white;
			box-shadow: $shadow-general;
			bottom: calc(100% + 1rem);
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			min-width: 20rem;
			padding: 1rem;
			border-radius: $radius-general;
		}

			.infobar-title {
				@include font-bold;
				display: block;
				margin-bottom: .5rem;
				color: $color-blue-dark;
			}
}