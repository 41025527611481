.modal-container.modal-options{
	.options-title {
		@include font-medium;
		font-size: 1.4rem;
		letter-spacing: -.024em;

		+ .options-message {
			margin-top: 1rem;
		}
	}

	&.alert {
		.modal-contentwrap {
			width: 32rem;
			text-align: center;
		}
	}

	.options-message {
		font-size: 1.4rem;
		letter-spacing: -.024em;
		line-height: 1.28em;
	}
	
	.options-opts {
		display: flex;
		justify-content: space-around;
		margin-top: 2.8rem;
	}

		.opts-item {
			flex: 1;
			padding-left: .5rem;
			padding-right: .5rem;

			+ .opts-item {
				margin-left: 1.4rem;
			}
		}
}