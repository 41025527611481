.pricebox {
	white-space: nowrap;

	&.colored {
		.pricebox-currency {
			color: $color-yellow-dark;
		}
	}

	&.warning {
		.pricebox-amount {
			@include font-bold;
			color: $color-orange-dark;
		}
	}

	&.conversion {
		margin-left: .5rem;
		color: $color-text-light;
		font-size: 1.1rem;

		.pricebox-currency {
			color: inherit;
		}
	}

	&.big {
		font-size: 1.8rem;
	}

	.pricebox-currency {
		@include font-medium;
		margin-right: .2em;
		display: inline-block;

		&.after{
			margin-left: .2em;
			margin-right: 0;
			font-size: .9em;
			vertical-align: sub;
		}
	}

	.pricebox-decimal {
		//margin-top: -.5rem;
		// vertical-align: middle;
		font-size: .8em;
		padding-bottom: 1%;
	}

	.pricebox-code {
		display: inline-block;
		font-size: .9em;
		margin-left: .4em;
	}

	.pricebox-aftertext {
		display: inline-block;
		font-size: .9em;
		margin-left: .4em;
	}

	.pricebox-amount {
		display: inline-flex;
		align-items: flex-end;
		flex-flow: row wrap;
		line-height: 1;
	}
}