.modal-container.modal-text{

	.modal-contentwrap {
		width: 80rem;

		&.wide {
			width: 90rem;
		}
	}

	.modal-content {
		padding: 3rem 2.7rem 4rem;
	}

	.text-title {
		margin-bottom: .7em;
		color: $color-blue;
	}

	.text-content {
		line-height: 1.1em;
	}
}