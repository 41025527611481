@include font-face('Roboto', '../../fonts/roboto-light', 300);
@include font-face('Roboto', '../../fonts/roboto-regular', 400);
@include font-face('Roboto', '../../fonts/roboto-medium', 500);
@include font-face('Roboto', '../../fonts/roboto-bold', 600);
@include font-face('Roboto', '../../fonts/roboto-black', 700);

body, html {
	height: 100%;
}

html{
	font-size: 10px;
}

body {
	@include font-main;
	line-height: 1;
	font-size: 1.4rem;
	color: $color-text;
	background-color: $color-bg;
	opacity: 1;

	/*&.overflowfix{
		.pagecontent{
			.fixed;
			width: 100%;
			height: 100vh;
			overflow: hidden;
		}

		&.overflowfix-hideformenu{
			background-color: @color-primary;
			.pagecontent{
				opacity: 0;
			}
		}
	}*/

	&.block-overflow, &.sidebar-block-overflow {
		overflow: hidden;
		height: 100%;
	}
}

#root {
	height: 100%;
}

@include media(1440) {
	html {
		@include calcFontSize(1440);
	}
}

@include media() {
	html {
		@include calcFontSize(375);
	}
}

.wrapper{
	padding: 0 2rem;
	max-width: 100%;
	margin: 0 auto;

	// @include media {
	// 	width: auto;
	// 	margin: 0 2rem;
	// }

	&.inline {
		max-width: 124rem;
		
		// &.wide {
		// 	max-width: 124rem;
		// }
	}
}

.sitewrap{
	@include clearfix;
}

.pagecontent{
	@include clearfix;
}

.contentwrap-aside{
	float: left;
	width: 25.6rem;
}

.contentwrap-main{
	float: right;
	width: calc(100% - 31.0rem);
	padding-top: 2.0rem;
	margin-right: 2.0rem;
}