@font-face {
	font-family: 'icomoon';
	src: url('../../fonts/icomoon.eot?1');
	src: url('../../fonts/icomoon.eot?1#iefix') format('embedded-opentype'),
	url('../../fonts/icomoon.woff?1') format('woff'),
	url('../../fonts/icomoon.ttf?1') format('truetype'),
		url('../../fonts/icomoon.svg?1#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@mixin icon($name, $type: 'self') {
	@if $type=='before' {
		&:before {
			@extend .icon-#{$name}, :before !optional;
			@extend %icomoon;
		}
	}

	@else if $type=='after' {
		&:after {
			@extend .icon-#{$name}, :before !optional;
			@extend %icomoon;
		}
	}

	@else {
		@extend .icon-#{$name} !optional;
		@extend %icomoon;
	}
}

%icomoon,
[class^="icon-"],
[class*=" icon-"] {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%icon-notification {
	content: "\e941";
}
	.icon-notification:before {
		@extend %icon-notification;
	}

%icon-store {
	content: "\e940";
}
	.icon-store:before {
		@extend %icon-store;
	}

%icon-brand {
	content: "\e93f";
}
	.icon-brand:before {
		@extend %icon-brand;
	}

%icon-map-pin {
	content: "\e93d";
}
	.icon-map-pin:before {
		@extend %icon-map-pin;
	}

%icon-cart {
	content: "\e93c";
}
	.icon-cart:before {
		@extend %icon-cart;
	}

%icon-torch-off {
	content: "\e939";
}
	.icon-torch-off:before {
		@extend %icon-torch-off;
	}

%icon-torch-on {
	content: "\e93a";
}
	.icon-torch-on:before {
		@extend %icon-torch-on;
	}

%icon-barcode {
	content: "\e938";
}
	.icon-barcode:before {
		@extend %icon-barcode;
	}

%icon-menu {
	content: "\e934";
}
	.icon-menu:before {
		@extend %icon-menu;
	}

%icon-close {
	content: "\e930";
}
	.icon-close:before {
		@extend %icon-close;
	}

%icon-conversion {
	content: "\e92f";
}
	.icon-conversion:before {
		@extend %icon-conversion;
	}

%icon-angle-left-thin {
	content: "\e92b";
}
	.icon-angle-left-thin:before {
		@extend %icon-angle-left-thin;
	}

%icon-angle-right-thin {
	content: "\e92c";
}
	.icon-angle-right-thin:before {
		@extend %icon-angle-right-thin;
	}

%icon-angle-down-thin {
	content: "\e92d";
}
	.icon-angle-down-thin:before {
		@extend %icon-angle-down-thin;
	}

%icon-angle-up-thin {
	content: "\e92e";
}
	.icon-angle-up-thin:before {
		@extend %icon-angle-up-thin;
	}

%icon-success {
	content: "\e922";
}
	.icon-success:before {
		@extend %icon-success;
	}

%icon-warning {
	content: "\e923";
}
	.icon-warning:before {
		@extend %icon-warning;
	}

%icon-alert {
	content: "\e924";
}
	.icon-alert:before {
		@extend %icon-alert;
	}

%icon-email {
	content: "\e920";
}
	.icon-email:before {
		@extend %icon-email;
	}

%icon-sms {
	content: "\e921";
}
	.icon-sms:before {
		@extend %icon-sms;
	}

%icon-copy {
	content: "\e91f";
}
	.icon-copy:before {
		@extend %icon-copy;
	}

%icon-user {
	content: "\e92a";
}
	.icon-user:before {
		@extend %icon-user;
	}

%icon-user-add {
	content: "\e91e";
}
	.icon-user-add:before {
		@extend %icon-user-add;
	}

%icon-link {
	content: "\e91c";
}
	.icon-link:before {
		@extend %icon-link;
	}

%icon-trash {
	content: "\e91d";
}
	.icon-trash:before {
		@extend %icon-trash;
	}

%icon-angle-up {
	content: "\e918";
}
	.icon-angle-up:before {
		@extend %icon-angle-up;
	}

%icon-angle-down {
	content: "\e919";
}
	.icon-angle-down:before {
		@extend %icon-angle-down;
	}

%icon-angle-left {
	content: "\e91a";
}
	.icon-angle-left:before {
		@extend %icon-angle-left;
	}

%icon-angle-right {
	content: "\e91b";
}
	.icon-angle-right:before {
		@extend %icon-angle-right;
	}

%icon-refresh {
	content: "\e917";
}
	.icon-refresh:before {
		@extend %icon-refresh;
	}

%icon-alert-2 {
	content: "\e911";
}
	.icon-alert-2:before {
		@extend %icon-alert-2;
	}

%icon-card {
	content: "\e912";
}
	.icon-card:before {
		@extend %icon-card;
	}

%icon-cards {
	content: "\e933";
}
	.icon-cards:before {
		@extend %icon-cards;
	}

%icon-info {
	content: "\e913";
}
	.icon-info:before {
		@extend %icon-info;
	}

%icon-star-empty {
	content: "\e914";
}
	.icon-star-empty:before {
		@extend %icon-star-empty;
	}

%icon-star-full {
	content: "\e915";
}
	.icon-star-full:before {
		@extend %icon-star-full;
	}

%icon-phone {
	content: "\e916";
}
	.icon-phone:before {
		@extend %icon-phone;
	}

%icon-check {
	content: "\e910";
}
	.icon-check:before {
		@extend %icon-check;
	}

%icon-arrow-down {
	content: "\e900";
}
	.icon-arrow-down:before {
		@extend %icon-arrow-down;
	}

%icon-arrow-left {
	content: "\e901";
}
	.icon-arrow-left:before {
		@extend %icon-arrow-left;
	}

%icon-arrow-right {
	content: "\e902";
}
	.icon-arrow-right:before {
		@extend %icon-arrow-right;
	}

%icon-arrow-up {
	content: "\e903";
}
	.icon-arrow-up:before {
		@extend %icon-arrow-up;
	}

%icon-users {
	content: "\e904";
}
	.icon-users:before {
		@extend %icon-users;
	}

%icon-dashboard {
	content: "\e905";
}
	.icon-dashboard:before {
		@extend %icon-dashboard;
	}

%icon-edit {
	content: "\e906";
}
	.icon-edit:before {
		@extend %icon-edit;
	}

%icon-excel {
	content: "\e907";
}
	.icon-excel:before {
		@extend %icon-excel;
	}

%icon-filter {
	content: "\e908";
}
	.icon-filter:before {
		@extend %icon-filter;
	}

%icon-list {
	content: "\e909";
}
	.icon-list:before {
		@extend %icon-list;
	}

%icon-payment {
	content: "\e90a";
}
	.icon-payment:before {
		@extend %icon-payment;
	}

%icon-minus {
	content: "\e93b";
}
	.icon-minus:before {
		@extend %icon-minus;
	}

%icon-plus {
	content: "\e90b";
}
	.icon-plus:before {
		@extend %icon-plus;
	}

%icon-report {
	content: "\e90c";
}
	.icon-report:before {
		@extend %icon-report;
	}

%icon-search {
	content: "\e90d";
}
	.icon-search:before {
		@extend %icon-search;
	}

%icon-settings {
	content: "\e90e";
}
	.icon-settings:before {
		@extend %icon-settings;
	}

%icon-spinner {
	content: "\e90f";
}
	.icon-spinner:before {
		@extend %icon-spinner;
	}

// Bunlar Yeniler
%icon-help {
	content: "\e925";
}
	.icon-help:before {
		@extend %icon-help;
	}

%icon-callcenter {
	content: "\e926";
}
	.icon-callcenter:before {
		@extend %icon-callcenter;
	}

%icon-plus-round {
	content: "\e927";
}
	.icon-plus-round:before {
		@extend %icon-plus-round;
	}

%icon-doublearrow-left {
	content: "\e928";
}
	.icon-doublearrow-left:before {
		@extend %icon-doublearrow-left;
	}

%icon-doublearrow-right {
	content: "\e929";
}
	.icon-doublearrow-right:before {
		@extend %icon-doublearrow-right;
	}

%icon-time {
	content: "\e931";
}
	.icon-time:before {
		@extend %icon-time;
	}

%icon-calculator {
	content: "\e932";
}
	.icon-calculator:before {
		@extend %icon-calculator;
	}

%icon-print {
	content: "\e935";
}
	.icon-print:before {
		@extend %icon-print;
	}

%icon-download {
	content: "\e936";
}
	.icon-download:before {
		@extend %icon-download;
	}

%icon-product {
	content: "\e937";
}
	.icon-product:before {
		@extend %icon-product;
	}

%icon-products {
	content: "\e93e";
}
	.icon-products:before {
		@extend %icon-products;
	}
