a.link, button.link {
	@include font-medium;
	color: $color-blue-light;
	transition: color $transition-hover ease;

	@include mediaMin {
		&:hover {
			color: darken($color-blue-light, $darken-ratio);
		}
	}

	&:active {
		color: darken($color-blue-light, $darken-ratio);
	}
}

.alert {
	// background-color: $color-bg;
	border: .1rem solid $color-gray;
	border-radius: $radius-general;
	color: $color-text-light;
	align-items: center;
	flex-flow: row wrap;
	display: flex;
	padding: 1.7rem 1.7rem 1.7rem;
	line-height: 2rem;
	font-size: 1.4rem;

	&.inline {
		display: inline-flex;
	}

	.alert-content {
		max-width: 100%;
		padding: .1rem 0;
		flex: 1 0 0;
		> * + * {
			margin-left: 1rem;
		}
	}

	// Colors
	// &.blue-light {
	// 	border-color: rgba($color-text, .1);
	// 	color: $color-blue-text-light;
	// }

	&.error {
		background-color: $color-error-bg;
		color: $color-error-text;
		border-color: $color-error-border;
	
	}
	&.success {
		background-color: $color-success-bg;
		color: $color-success-text;
		border-color: $color-success-border;
	}
	
	&.warning {
		background-color: $color-warning-bg;
		color: $color-warning;
		border-color: $color-warning-border;
	}

	// Positions
	
	&.center {
		justify-content: center;
		align-items: center;

		.alert-content {
			flex: none;
			text-align: center;
		}
	}

	&.top {
		align-items: flex-start;
	}

	.alert-icon {
		margin-right: 1.5rem;
		display: inline-block;
		vertical-align: middle;
		font-size: 2.2rem;

		// Sizes
		&.small {
			font-size: 1.4em;
		}

		&.big {
			font-size: 3.2rem;

			+ .alert-content {
				padding: .6rem 0;
			}
		}

		// Colors
		&.yellow {
			color: $color-yellow;
		}
		&.yellow-dark {
			color: $color-yellow-dark;
		}
	}

	.alert-controls {
		text-align: right;

		@include media {
			width: 100%;
			margin-top: 1rem;
			text-align: center;
		}

		> * + * {
			margin-left: 2rem;
		}
	}
}

.page-message {
	margin-bottom: 2rem;
}

@include media {
	.show-web {
		display: none!important;
	}
	.hide-mobile {
		display: none!important;
	}
}

@include mediaMin {
	.show-mobile {
		display: none!important;
	}
	.hide-web {
		display: none!important;
	}
}

.printarea {
	display: none;
}

@media print
{
	body {
		background-color: $color-white;
		* {
			visibility: hidden;
			position: static!important;
			height: 0;
		}
	}

	.section.admin-container .container-contentwrap {
		min-height: auto!important;
	}

	.printarea {
		display: block;
		position: absolute!important;
		top: 0;
		left: 0;
		height: auto;
		visibility: visible;

		* { visibility: visible; height: auto; }
	}
}