@mixin font-light{ font-weight: 300; }
@mixin font-regular{ font-weight: 400; }
@mixin font-medium{ font-weight: 500; }
@mixin font-bold{ font-weight:  600; }
@mixin font-black{ font-weight:  700; }

@mixin font-main{
	@include font-regular;
	font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.text-yellow-dark {
	color: $color-yellow-dark;
}

.text-error {
	color: $color-error;
}