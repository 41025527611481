.grid-container {
	width: 100%;
	display: flex;
	flex-flow: row wrap;

	.row {
		margin: 0 -1.0rem;
		width: calc(100% + 2.0rem);
		display: flex;
		flex-flow: row wrap;

		&:first-child {
			margin-top: -1rem;
		}

		&:last-child {
			margin-bottom: -1rem;
		}

		&.val-bottom {
			align-items: flex-end;
		}

		&.val-center {
			align-items: center;;
		}

		&.inner{
			margin-top: -1.0rem;
			margin-bottom: -1.0rem;
		}

		+ .row {
			&.apart {
				margin-top: 1.4rem;

				&.lined {
					&:before {
						margin-bottom: .7rem;
					}
				}
			}

			&.lined {
				&:before {
					@include pseudo;
					border-top: .2rem solid rgba($color-text, .1);
					margin-left: 1rem;
					margin-right: 1rem;
					margin-top: .8rem;
					margin-bottom: .5rem;
					width: 100%;
				}
			}
		}
	}

	.col {
		width: 100%;
		padding: 1.0rem 1.0rem;

		@include mediaMin {
			&.x12 {
				width: 100%;
			}

			&.x11 {
				width: 91.666666666666667%;
			}

			&.x10 {
				width: 83.333333333333333%;
			}

			&.x9 {
				width: 75%;
			}

			&.x8 {
				width: 66.666666666666667%;
			}

			&.x7 {
				width: 58.333333333333333%;
			}

			&.x6 {
				width: 50%;
			}

			&.x5 {
				width: 41.666666666666667%;
			}

			&.x4 {
				width: 33.333333333333333%;
			}

			&.x3 {
				width: 25%;
			}

			&.x2 {
				width: 16.666666666666667%;
			}

			&.x1 {
				width: 8.333333333333333%;
			}

			&.flex {
				flex: 1 0 0;
			}

			&.free {
				width: auto;
			}

			// Alignment

			&.al-center {
				text-align: center;
			}

			&.al-left {
				text-align: left;
			}

			&.al-right {
				text-align: right;
			}
		}

		@include media {
			&.m-x12 {
				width: 100%;
			}

			&.m-x11 {
				width: 91.666666666666667%;
			}

			&.m-x10 {
				width: 83.333333333333333%;
			}

			&.m-x9 {
				width: 75%;
			}

			&.m-x8 {
				width: 66.666666666666667%;
			}

			&.m-x7 {
				width: 58.333333333333333%;
			}

			&.m-x6 {
				width: 50%;
			}

			&.m-x5 {
				width: 41.666666666666667%;
			}

			&.m-x4 {
				width: 33.333333333333333%;
			}

			&.m-x3 {
				width: 25%;
			}

			&.m-x2 {
				width: 16.666666666666667%;
			}

			&.m-x1 {
				width: 8.333333333333333%;
			}

			&.m-flex {
				flex: 1 0 0;
			}

			&.m-free {
				width: auto;
			}

			// Alignment

			&.m-al-center {
				text-align: center;
			}

			&.m-al-left {
				text-align: left;
			}

			&.m-al-right {
				text-align: right;
			}
		}

		&.vertical-full {
			display: flex;
			flex-direction: column;

			> * {
				flex: 1 0 0;
			}

			> .inputwrap {
				.input-outerwrap {
					height: 100%;
				}

				.input-innerwrap {
					height: 100%;
				}

				.inputwrap-input {
					height: 100%;
					max-height: 100%;
					min-height: 100%;
				}
			}
		}

		&.no-gap-top {
			padding-top: 0;
		}

		&.no-gap-bottom {
			padding-bottom: 0;
		}

		&.gap-top {
			padding-top: 2rem;
		}

		&.gap-bottom {
			padding-bottom: 2rem;
		}
	}

	hr {
		width: 100%;
		border-color: $color-gray-light;
		margin: 1.5rem 0;
	}

	&.narrow{
		.row{
			margin: 0 -.5rem;
			width: calc(100% + 1.0rem);

			// &.inner{
			// 	margin-top: -.5rem;
			// 	margin-bottom: -.5rem;
			// }
		}
		.col{
			// padding: .5rem .5rem;
			padding-left: .5rem;
			padding-right: .5rem;
		}
	}

	&.low, .row.low {
		.col {
			padding-top: .7rem;
			padding-bottom: .7rem;
		}
	}
}