.quantity-input {
	display: inline-flex;
	flex-flow: row wrap;
	border: .1rem solid $color-text;
	border-radius: $radius-general;
	overflow: hidden;
	height: 4rem;
	min-width: 12rem;
	
	&.big {
		height: 5.2rem;
		min-width: 24rem;

		> .btn {
			height: 5rem;
		}
	}

	> .btn {
		border: none!important;
		border-radius: 0;
		height: 3.8rem;
	}

	.input-addbtn {
		flex: 1 0 0;
	}

	.input-quantity {
		flex: 1 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 2rem;
	}
}