.modal-order-stores-map {
	.map-elem {
		padding-top: 0;
		padding-bottom: 2rem;
		
		.mapelement-marker {
			&.no-stock {
				opacity: .5;
			}

			&.user-store {
				.marker-btn {
					background-color: $color-red;
				}
			}
		}
	}
}