.modal-container.modal-options.confirm{

	.modal-contentwrap {
		width: 27rem;
		text-align: center;

		&.wide {
			width: 58rem;
		}
	}

	.opts-item {
		//flex: 1;

		+ .opts-item {
			margin-left: 0;
		}
	}
}