.collapser {
	font-size: 1.4rem;
	border: .1rem solid $color-gray;
	border-radius: $radius-general;

	&.active {
		.collapser-content {
			display: block;
			transition-duration: $transition-anim-fast;
		}

		&.show {
			.collapser-content {
				overflow: visible;
			}

			.collapser-innerwrap {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				transition-duration: $transition-anim;
			}

			.collapser-btn {
				.btn-icon {
					transform: rotate(90deg);
				}
			}

			&.shown {
				.collapser-content {
					max-height: none;
				}
			}
		}
	}

	.collapser-btn {
		width: 100%;
		text-align: left;
		min-height: 4rem;
		display: flex;
		justify-content: space-between;
		flex-flow: row wrap;
		align-items: center;
		padding: 0 1.5rem;
		// transition: background-color $transition-hover ease;
		transition: color $transition-hover ease;

		@include mediaMin {
			&:hover {
				// background-color: $color-bg-light;
				color: $color-blue;
			}
		}

		&:active {
			transition-duration: $transition-superfast;
			// background-color: $color-bg;
			color: $color-blue;
		}

		.btn-text {
			@include font-bold;
			flex: 1 0 0;
			font-size: inherit;
			display: flex;
			align-items: center;
			justify-content: flex-start;
	
			> * {
				margin-right: 1rem;
			}
		}
	
		.btn-icon {
			font-size: .9rem;
			color: $color-text-light;
			transition: transform $transition-hover ease;
		}
	}

	.collapser-content {
		display: none;
		max-height: 0;
		transition: max-height $transition-anim-fast ease;
		overflow: hidden;
		will-change: max-height;
		line-height: 1.28em;
	}

	.collapser-innerwrap {
		opacity: 0;
		// padding: 1.5rem 1.5rem 2rem;
		padding: 0 1.5rem 2rem;
		transform: translate3d(0, -3rem, 0);
		transition:
			opacity $transition-anim-fast ease,
			transform $transition-anim-fast ease;
	}

	.collapser-section {
		padding: 1rem 1.5rem;
		margin: 0 -1.5rem;
		width: calc(100% + 3rem);

		&.border-top {
			border-top: .1rem solid $color-gray;
		}

		&.border-bottom {
			border-bottom: .1rem solid $color-gray;
		}

		&:first-child {
			margin-top: -1.5rem;
			padding-top: 1.5rem;
		}

		&:last-child {
			margin-bottom: -2rem;
			padding-bottom: 2rem;
		}
	}
}