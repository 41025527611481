.products-table {
	// .table-product {}

		.product-image {
			width: 8rem;
		}

	&.mobile-state {
		// .table-product {}
		
			.product-image {
				width: 6rem;

				.table-imagewrap {
					width: 4rem;
					height: 4rem;
				}
			}

			.product-description {
				width: calc(100% - 6rem);
				padding-left: 0;
			}

			.product-stockinfo {
				padding-left: 6rem;
				padding-bottom: 2rem;
				width: 100%;
				padding-top: 0;
			}

			.product-price {
				padding-left: 6rem;
				width: 100%;
				padding-top: 0;
			}

		.controls {
			display: flex;
			justify-content: space-between;
			
			> * + * {
				flex: 1 0 0;
				margin: 0 0 0 1rem;
			}
		}
	}
}