.order-delivery-info {
	display: inline-block;
	background-color: $color-bg;
	border-radius: $radius-general;
	// color: $color-white;
	padding: 1rem;

	.info-title {
		display: block;
	}

	&.type-2 {
		background-color: $color-tan-bg;
		// .info-title {
		// 	color: $color-purple;
		// }
	}

	&.type-1 {
		background-color: $color-success-bg;
		// .info-title {
		// 	color: $color-success;
		// }
	}

	&.type-3 {
		background-color: $color-blue-bg;
		// .info-title {
		// 	color: $color-blue;
		// }
	}
}