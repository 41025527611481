$color-focus: $color-text;

@mixin input-children {
	.file-container,
	select + label,
	.inputwrap-input,
	textarea,
	.selectwrap-label,
	.odedim_select__control,
	.input-uploadlabel {
		@content;
	}
}

.inputwrap {
	touch-action: manipulation;
	display: block;
	position: relative;
	text-align: left;
	z-index: 1;

	@for $i from 1 through 10 {
		&.z-#{$i} {
			z-index: (2 + $i)!important;
		}
	}

	@include input-children {
		@include font-main;
		@include font-regular;
		touch-action: manipulation;
		color: $color-text;
		background-color: transparent;
		padding: 2.6rem 1rem .6rem;
		line-height: 1.8rem;
		font-size: 1.4rem;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		display: block;
		border: none;
		box-shadow: none;
		border-radius: $radius-general;
		box-shadow: $shadow-input;
		border: .1rem solid $color-gray;
		background-color: $color-white;
		transition: color $transition-hover ease, border-color $transition-hover ease;
		-webkit-appearance: none;
		box-sizing: border-box;

		&:-internal-autofill-selected {
			border-color: darken($color-autofill, 20%);
		}
		

		// &:focus {
		// 	//border-color: rgba($color-black, .6);
		// }

		@include placeholder() {
			color: rgba($color-text, .25);
			transition: color $transition-hover ease;
		}

		@include media(430) {
			font-size: 16px;
		}
	}

	.input-outerwrap {
		touch-action: manipulation;
		//position: relative;

		&.no-label {
			@include input-children {
				padding-top: .8rem;
				padding-bottom: .8rem;
			}
		}
	}

	// States

	&.error {
		@include input-children {
			border-color: $color-error;
		}

		// .input-label {
		// 	color: $color-error;
		// }
	}

	&.disabled {
		opacity: .7;

		@include input-children {
			background-color: $color-bg;
		}
	}

	&.input-full {

		&.input-valid {
			@include input-children {
				border-color: $color-success;
			}
		}
	}

	// .input-innerwrap {
	// 	//z-index: 1;
	// }

	.input-label {
		@include font-bold;
		left: 0;
		top: 0;
		right: 0;
		background-color: transparent;
		display: block;
		font-size: 1.1rem;
		line-height: 1.4rem;
		padding: 1.1rem 1.1rem .4rem;
		letter-spacing: .05em;
		color: $color-text-light;
		transition: color $transition-hover ease;
		text-transform: uppercase;
		z-index: 2;
		margin-bottom: -2.9rem;
		position: relative;
		z-index: 2;
		pointer-events: none;
		
		.label-text {
			display: block;
			max-width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			// text-shadow: rgb(255, 255, 255) 4px 0px 0px, rgb(255, 255, 255) 3.87565px 0.989616px 0px, rgb(255, 255, 255) 3.51033px 1.9177px 0px, rgb(255, 255, 255) 2.92676px 2.72656px 0px, rgb(255, 255, 255) 2.16121px 3.36588px 0px, rgb(255, 255, 255) 1.26129px 3.79594px 0px, rgb(255, 255, 255) 0.282949px 3.98998px 0px, rgb(255, 255, 255) -0.712984px 3.93594px 0px, rgb(255, 255, 255) -1.66459px 3.63719px 0px, rgb(255, 255, 255) -2.51269px 3.11229px 0px, rgb(255, 255, 255) -3.20457px 2.39389px 0px, rgb(255, 255, 255) -3.69721px 1.52664px 0px, rgb(255, 255, 255) -3.95997px 0.56448px 0px, rgb(255, 255, 255) -3.97652px -0.432781px 0px, rgb(255, 255, 255) -3.74583px -1.40313px 0px, rgb(255, 255, 255) -3.28224px -2.28625px 0px, rgb(255, 255, 255) -2.61457px -3.02721px 0px, rgb(255, 255, 255) -1.78435px -3.57996px 0px, rgb(255, 255, 255) -0.843183px -3.91012px 0px, rgb(255, 255, 255) 0.150409px -3.99717px 0px, rgb(255, 255, 255) 1.13465px -3.8357px 0px, rgb(255, 255, 255) 2.04834px -3.43574px 0px, rgb(255, 255, 255) 2.83468px -2.82216px 0px, rgb(255, 255, 255) 3.44477px -2.03312px 0px, rgb(255, 255, 255) 3.84068px -1.11766px 0px, rgb(255, 255, 255) 3.9978px -0.132717px 0px;
		}

		.label-asterisk {
			position: absolute;
			right: 1rem;
			top: .8rem;
			color: $color-warning;
			font-size: 1.4rem;
		}
	}

		.label-nth {
			@include font-regular;
			float: right;
			color: rgba($color-text, .5);

			@include media {
				display: none;
			}
		}

		.label-asterisk {
			//color: $color-red;
			font-size: 1.6rem;
			//margin-left: .3rem;
		}

	.input-error {
		@include font-light;
		font-size: 1.2rem;
		line-height: 1.2rem;
		padding: .7rem 0 .3rem;
		color: $color-error;
		display: inline-block;
		//background-color: $color-error;
		//white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: left;
		//padding-bottom: .9rem;
	}

	.input-icon {
		position: absolute;
		font-size: 1.8rem;
		line-height: 1.8rem;
		color: $color-text;
		top: 50%;
		left: 1.5rem;
		transform: translate3d(0, -50%, 0);
		pointer-events: none;
		z-index: 2;
	}

	.input-info {
		position: absolute;
		top: 2.3rem;
		right: 1.1rem;
		color: rgba($color-text, .5);
		transform: translate3d(0, -50%, 0);
		font-size: 1.4rem;
		line-height: 0;

		.popinfo-content {
			min-width: 20rem;
		}
	}

	/// Types
	&.type-textarea {
		textarea {
			max-width: 100%;
			resize: none;
		}
	}

	&.type-wysiwyg {
		.rdw-editor-toolbar {
			background-color: $color-bg;
			border: none;
			width: calc(100% + 2rem);
			margin: .5rem -1rem 0;
			font-size: 1.5rem;
			padding: .6rem .6rem 0;
		}

		.rdw-option-wrapper {
			margin: 0 .4rem;
			border-radius: $radius-general;
			min-width: 2.5rem;
			height: 3rem;
			padding: .5rem .9rem;
			border: .1rem solid $color-gray;

			&.rdw-option-active {
				box-shadow: none;
				border-color: $color-blue;
				background-color: $color-blue-bg;
			}

			&:hover {
				box-shadow: $shadow-input;
			}
		}

		.rdw-dropdown-wrapper {
			height: 3rem;
			border-radius: $radius-general;
		}

		.rdw-inline-wrapper {
			margin: 0 0 .6rem;
		}

		.rdw-block-wrapper {
			margin: 0 0 .6rem;
		}

		.rdw-editor-main {
			width: calc(100% + 2rem);
			margin: 0 -1rem -.6rem;
		}

		.public-DraftEditor-content {
			padding: 1rem;
			height: 30rem;
			overflow: auto;
		}

		.public-DraftEditorPlaceholder-root {
			padding: 2.5rem 1rem;
		}

		.rdw-editor-sourceinput {
			position: relative;
			width: 100%;
			height: 30rem;
			padding: 2.5rem 1rem;
			border: none;
			box-shadow: none;
			resize: none;
		}

		// .DraftEditor-root {
		// }
	}
	
	&.type-text, &.type-textarea {
		&:not(.input-valid):not(.disabled) {
			.inputwrap-input {
				&:focus:not(:-internal-autofill-selected) {
					border-color: $color-focus;
				}
			}
		}
	}

	&.type-file {

		&.input-full {
			// input[type="file"]{
			// }

			.file-container {
				@include font-regular;
				color: $color-text;
				font-size: 1.2rem;
			}

			.input-icon {
				color: $color-text;
			}
		}

		input[type='file'] {
			pointer-events: none;
			touch-action: manipulation;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 2rem;
			height: 2rem;
			margin: 0;

		}

		.input-label {
			cursor: pointer;
		}

		.file-container {
			color: rgba($color-text, .25);
			cursor: pointer;
			//text-overflow: ellipsis;
			overflow: hidden;
			margin: 0;
			//padding-left: 2.5rem;
			min-height: 5.1rem;
			//padding-top: 0;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-items: center;
		}

			.container-addicon {
				font-size: .7em;
				margin-right: .5rem;
			}

			.container-file {
				display: inline-block;
				margin-right: 1rem;
				background-color: $color-bg;
				padding: .2rem .5rem;
				font-size: 1.2rem;
				margin-bottom: .2rem;
				border-radius: $radius-general;
			}

			.container-remove {
				opacity: .8;
				//width: 1.2rem;
				transition: opacity $transition-hover ease;
				white-space: nowrap;
				border-radius: $radius-general;

				> i {
					font-size: 1rem;
				}

				@include mediaMin {
					&:hover {
						opacity: 1;
					}
				}

				&:focus {
					opacity: 1;
				}
			}

		.input-icon {
			font-size: 2rem;
			color: $color-text;
		}

		&.hide-empty {
			display: none;

			&.input-full {
				display: block;
			}
		}
	}

	&.type-date {
		z-index: 2;

		&:not(.input-valid):not(.disabled) {
			.inputwrap-input {
				&:focus:not(:-internal-autofill-selected) {
					border-color: $color-focus;
				}

				&.focused {
					border-color: $color-focus;
				}
			}
		}

		// .datepicker {
		// }
	}

	&.type-select {
		z-index: 2;

		&.disabled {
			.odedim_select {
				.odedim_select__input {
					input {
						pointer-events: none;
					}
				}
			}
		}

		&:not(.disabled) {
			.odedim_select {
				.odedim_select__control {
					&.odedim_select__control--is-focused,
					&.odedim_select__control--menu-is-open {
						border-color: $color-focus;

						&:hover {
							border-color: $color-focus;
						}

						.odedim_select__indicator {
							color: $color-text;
						}
					}
				}
			}
		}

		.odedim_select {
			.odedim_select__control {
				display: flex;
				min-height: auto;
				align-items: center;

				&:hover {
					border-color: $color-gray;
				}
			}

				.odedim_select__menu {
					@include font-regular;
					background-color: $color-white;
					box-shadow: $shadow-input;
					border: .1rem solid $color-gray;
					margin-top: .8rem;
					z-index: 20;
				}

			.odedim_select__option {
				cursor: pointer;

				&.odedim_select__option--is-disabled {
					background-color: $color-white;
					color: $color-text-light;
					cursor: default;
				}

				&.odedim_select__option--is-focused {
					background-color: $color-bg;
					color: $color-text;

					&.odedim_select__option--is-disabled {
						color: $color-text-light;
					}
				}

				&.odedim_select__option--is-selected {
					background-color: $color-blue;
					color: $color-white;
				}
			}
			.odedim_select__indicator {
				padding: 0;
				font-size: 1rem;
				color: rgba($color-text, .5);
			}

			.odedim_select__single-value {
				padding: 0;
				margin: 0;
				color: inherit;
			}

			.odedim_select__placeholder {
				margin: 0;
				color: rgba($color-text, .25);
			}

			// .odedim_select__placeholder, .odedim_select__single-value {
			// 	+ div {
			// 		padding: 0;
			// 		margin:  0;
			// 		// margin: .4rem 0;
			// 	}
			// }

			.odedim_select__value-container {
				flex: 1 0 0;
				padding: 0;
				min-height: 1.8rem;

				> div {
					padding: 0;
					margin: 0;
				}
			}

			.odedim_select__value-container--is-multi {
				&.odedim_select__value-container--has-value {
					margin-bottom: -.4rem;
				}
			}

			.odedim_select__multi-value {
				border-radius: $radius-general;
				background-color: $color-gray-light;
				margin: 0rem 0;
				margin-right: .4rem;
				margin-bottom: .4rem;
			}

			.odedim_select__multi-value__remove {
				padding: 0 .4rem 0 .2rem;
				border-radius: 0;
				border-top-right-radius: $radius-general;
				border-bottom-right-radius: $radius-general;
				cursor: pointer;
				transition: background-color $transition-hover ease;

				&:hover {
					background-color: $color-gray;
					color: inherit;
				}

				> svg {
					width: 1.4rem!important;
					height: 1.4rem!important;
				}
			}

			.odedim_select__multi-value__label {
				padding: 0 .2rem 0 1rem;
			}

			.odedim_select__clear-indicator {
				margin-right: .4rem;
				cursor: pointer;

				> svg {
					width: 1.6rem!important;
					height: 1.6rem!important;
				}
			}

			.odedim_select__loader {
				@include spin;
				position: absolute;
				right: 3rem;
				top: calc(.8rem + 50%);
				color: rgba($color-text, .25);
				transform: translate3d(-50%, -50%, 0);
			}
		}
	}

	&.type-checkbox, &.type-radio {
		display: inline-block;

		// + .inputwrap.type-checkbox, .inputwrap.type-radio {
		// 	margin-left: 2rem;
		// }

		.input-label {
			padding: .7rem 0 0;
			margin-bottom: .3rem;
		}

		.checkwrap {
			@include font-regular;
			position: relative;

			input {
				opacity: 0;
				position: absolute;
				left: 0;
				top: 0;
				pointer-events: none;
				// min-width: auto;
				// width: auto;

				&:focus {
					+ label {
						> .label-check {
							border-color: $color-focus;
						}
					}
				}

				&:checked {
					+ label{
						//color: $color-red;

						> .label-check{
							// border-width: .2rem;
							// border-color: $color-text;
							background-color: $color-blue;
							border-color: $color-blue;

							&:before {
								opacity: 1;
								transform: scale(1) translate3d(0, 5%, 0);
							}
						}
					}

					&:focus {
						+ label {
							> .label-check {
								border-color: $color-focus;
							}
						}
					}
				}

				&:disabled {
					+ label {
						> .label-check {
							opacity: .6;
						}

						.label-content {
							opacity: .5;
						}
					}
				}

				+ label {
					display: flex;
					flex-flow: row wrap;
					align-items: center;
					position: relative;
					font-size: 1.4rem;
					letter-spacing: -0.015em;
					padding: .4rem 0 .4rem 0rem;
					line-height: 1.2em;
					cursor: pointer;
					transition: color $transition-hover ease;

					@include media {
						font-size: 1.2rem;

					}

					> .label-check {
						width: 1.6rem;
						height: 1.6rem;
						border-radius: $radius-general;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						box-shadow: $shadow-input;
						border: .1rem solid $color-gray;
						cursor: pointer;
						margin-right: 1.3rem;
						transition: border-color $transition-hover ease, background-color $transition-hover ease;

						&:before {
							@include icon('check');
							display: block;
							font-size: .8rem;
							opacity: 0;
							color: $color-white;
							transition: opacity $transition-hover ease;
							transform: scale(.9) translate3d(0, 5%, 0);
						}
					}

					.check-link {
						color: $color-red;
					}

					.label-content {
						flex: 1 0 0;
						margin-top: .1em;
					}
				}
			}
		}

		&:not(.block){
			// display: block;
			
			.input-label {
				padding: 0;
				margin-bottom: .3rem;
			}

			.checkwrap {
				display: inline-block;
				+ .checkwrap {
					margin-left: 2rem;
				}
			}

			&.has-label {
				padding: .8rem 0;
			}

			&.error {
				.checkwrap {
					input + label {
						color: $color-error;
					}
				}
			}

			&:not(:last-child) {
				margin-right: 2rem;
			}
		}

		&.block {
			display: block;

			&.error {
				.input-opts {
					border-color: $color-error;
				}
				
				.checkwrap {
					+ .checkwrap {
						border-top-color: $color-error;
					}
					
					input + label {
						background-color: $color-error-bg;
						> .label-check {
							border-color: $color-error;
						}
					}
				}
			}

			.input-opts {
				display: block;
				border: .1rem solid $color-gray;
				border-radius: $radius-general;
				overflow: hidden;
				box-shadow: $shadow-input;
				transition: border-color $transition-hover ease, background-color $transition-hover ease;
			}

			.input-label {
				margin-bottom: 1.2rem;
			}

			.checkwrap {
				// flex: 1 0 0;
				width: 100%;
				display: flex;

				@include media {
					width: 100%;
				}

				input {
					+ label {
						display: flex;
						flex: 1 0 0;
						align-items: flex-start;
						flex-flow: row wrap;
						padding: 1.6rem 2rem 1.6rem 1rem;
						transition:
							color $transition-hover ease,
							background-color $transition-hover ease;
					}

					&:checked {
						+ label {
							color: $color-blue;
							background-color: $color-blue-bg;
						}
					}
				}

				.label-content {
					margin-top: 0;
				}

				+ .checkwrap {
					border-top: .1rem solid $color-gray;
				}
			}
		}

		&.hollow {
			.checkwrap {
				input{
					& , &:checked {
						+ label {
							> .label-check {
								border: none;
								box-shadow: $shadow-input;
							}
						}
					}
				}

			}
		}
	}

	&.type-radio {

		.checkwrap {

			input {
				// &:checked {
				// 	+ label{
				// 		> .label-check {
				// 			&:before {
				// 				transform: scale(1) translate3d(0, 0%, 0);
				// 			}
				// 		}
				// 	}
				// }

				+ label {
					
					> .label-check {
						border-radius: 50%;
					}
				}
			}
		}
	}

	&.type-captcha {
		@include media {
			.input-outerwrap {
				overflow: hidden;
			}
			.input-innerwrap {
				width: 120%;
				margin: 0 -10%;
				// transform: scale(.8);
				text-align: center;

				// > div {
				// 	display: inline-block;
				// }

				> div > div {
					width: 275px!important;
					transform: scale(.82);
				}
			}
		}
	}

	&.big {
		@include input-children {
			@include font-light;
			font-size: 3rem;
			line-height: 3rem;
			color: $color-blue;
			padding: 2.4rem 1rem 0.3rem;

		}
	}

	&.low {
		@include input-children {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	&.lower {
		@include input-children {
			padding-top: .8rem;
			padding-bottom: .8rem;
		}
	}

	&.high {
		input[type='file'] + label,
		select + label,
		.inputwrap-input,
		{
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
		}

		.input-icon {
			top: 2.5rem;
		}

		.input-info {
			top: 2.5rem;
		}
	}

	&.has-icon {
		@include input-children {
			padding-left: 4.4rem;
		}
	}
}

// Native Select Controls
// .selectwrap {
// 	display: inline-block;
// 	position: relative;

// 	.selectwrap-label {
// 		position: relative;
// 		display: flex;
// 		width: 100%;
// 		align-items: center;
// 		justify-content: space-between;

// 		&.placeholder {
// 			color: rgba($color-text, .25);
// 		}

// 		&:after {
// 			@include icon('angle-down');
// 			font-size: .8rem;
// 		}
// 	}

// 	select {
// 		position: absolute;
// 		opacity: 0;
// 		top: 0;
// 		left: 0;
// 		width: 100%;
// 		height: 100%;
// 	}
// }

.form-container {

	.form-group + .form-group {
		margin-top: 1.8rem;
		
		&.apart {
			margin-top: 2.4rem;

			&.lined {
				&:before {
					margin-bottom: 1.7rem;
				}
			}
		}

		&.narrow {
			margin-top: 1rem;
		}

		&.lined {
			&:before {
				@include pseudo;
				border-top: .2rem solid rgba($color-text, .1);
				margin-bottom: 1rem;
			}
		}
	}

	.form-content {
		position: relative;
		z-index: 2;

		+ .form-controls {
			position: relative;
			z-index: 1;
		}
	}

	.form-controls {
		text-align: right;
		padding-top: 2rem;

		> * + * {
			margin-left: 1rem;
		}
	}
}

.form-message {
	margin-bottom: 1rem;
	border-radius: $radius-general;
	border: .1rem solid $color-gray;
	background-color: $color-bg;
	padding: 1rem 1rem;
	font-size: 1.4rem;
	display: block;
	width: 100%;

	&.error {
		border-color: $color-error-bg;
		color: $color-white;
		background-color: $color-error-bg;
	}

	&.blue {
		border-color: $color-blue-light;
		color: $color-blue-dark;
		background-color: $color-blue-bg;
	}
}

.form-note {
	font-size: 1.2rem;
	margin-top: 1.4rem;
	line-height: 1.3em;

	> * + * {
		margin-top: .5em;
	}

	&.error {
		color: $color-error;
	}

	&.boxed {
		background-color: $color-bg;
		border-radius: $radius-general;
		padding: 1rem;
	}

	.quote {
		background-color: $color-white;
		border: .1rem solid $color-gray;
		padding: .3rem .5rem;
		border-radius: $radius-general;
		display: inline-block;
		word-break: break-word;
	}
}