.messenger-container {
	position: fixed;
	top: 1rem;
	width: 50rem;
	max-width: calc(100% - 3rem);
	right: 1.5rem;
	z-index: 55;
	//margin: 1rem 1.5rem;

	.messenger-message {
		font-size: 1.6rem;
		background-color: $color-warning;
		color: $color-white;
		border-radius: $radius-general;
		padding: 1rem 1.8em;
		opacity: 0;
		transform: translate3d(0, -.5rem, 0);
		transition:
			transform $transition-anim ease,
			opacity $transition-anim ease,
			background-color $transition-anim ease;
		cursor: pointer;
		width: 100%;

		+ .messenger-message{
			margin-top: 1rem;
		}

		&.show {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}

		&.error {
			background-color: $color-error;

			@include mediaMin {
				&:hover {
					background-color: darken($color-error, $darken-ratio);
				}
			}

		}

		&.warning {
			background-color: $color-warning;

			@include mediaMin {
				&:hover {
					background-color: darken($color-warning, $darken-ratio);
				}
			}
		}

		&.success {
			background-color: $color-success;

			@include mediaMin {
				&:hover {
					background-color: darken($color-success, $darken-ratio);
				}
			}
		}
	}
}