.section.loader {
	position: fixed;
	z-index: 50;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $color-blue;

	.loader-content {
		text-align: center;
	}

	.loader-logo {
		display: block;
		width: 10rem;
		opacity: .3;
		margin: 0 auto;
	}

	.loader-message {
		font-size: 1.1rem;
		color: rgba($color-white, .5);
		margin-top: 1.5rem;
	}
}