.portlet{
	//@include clearfix;
	display: flex;
	flex-flow: row wrap;
	box-shadow: $shadow-general;
	background-color: $color-white;
	border-radius: $radius-big;
	border: .1rem solid $color-gray;
	padding: 2rem 2rem;

	+ .portlet {
		margin-top: 2rem;
	}

	&.loader-container {
		> .loading-indicator {
			border-radius: $radius-big;
		}
	}

	&.inline {
		display: inline-flex;
	}

	&.gray{
		background-color: $color-bg-light;
	}

	&.error{
		background-color: $color-error-bg;
		color: $color-error-text;
		border-color: $color-error-bg;

		.loading-indicator {
			background-color: $color-error-bg;
			color: $color-error-text;
		}
	}

	&.yellow {
		background-color: $color-yellow-bg;
	}

	&.yellow-light {
		background-color: $color-yellow-bg-light;
		color: $color-yellow-text;
	}

	&.green, &.success {
		background-color: $color-success-bg;
	}

	.portlet-header {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		width: calc(100% + 4rem);
		margin: -2rem -2rem 0;
		padding: 2rem 2rem 0rem;

		&.small {
			.portlet-title {
				font-size: 1.1rem;
				text-transform: uppercase;
			}
		}

		&.border-bottom{
			padding-bottom: 2rem;
			border-bottom: .1rem solid $color-gray;	
		}

		&.gap-bottom{
			padding-bottom: 2rem;
		}

		.portlet-infobar {
			flex: 1 0 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		@include media {
			> * {
				width: 100%;
				flex: none!important;
			}

			.portlet-title {
				order: 1;

				+ .header-controls {
					margin-top: 1.5rem;
				}
			}

			.header-controls {
				order: 2;
			}

			.portlet-infobar {
				order: 3;

				+ .portlet-controls {
					margin-bottom: 1.5rem;
				}
			}
		 }
	}

		.header-controls{
			text-align: right;
			font-size: 0;
			letter-spacing: 0;

			&.left{
				float: left;
				text-align: left;
			}

			&.block{
				width: 100%;
				width: auto;
			}

			@include mediaMin {
				> *{
					margin-right: 1.0rem;
					&:last-child{
						margin-right: 0;
					}
				}
			}

			@include media {
				> * {
					margin-bottom: 1rem;

					&:last-child {
						margin-bottom: 0;
					}
					
					+ * {
						margin-left: 1rem;
					}
				}
			}
		}

	.portlet-title{
		@include font-bold;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;
		justify-content: flex-start;
		font-size: 1.6rem;

		> * + * {
			margin-left: 1rem;
		}

		@include media {
			display: block;

			> *:not(.label) {
				width: 100%;
				display: block;
			}

			> * + * {
				margin: .5rem 0 0;
			}
		}
	}

		.title-toptext {
			@include font-regular;
			font-size: .9em;
			margin-bottom: .3rem;
			display: block;
			width: 100%;
			color: $color-text-light;
		}

		.title-subtitle {
			color: $color-text-light;
			font-size: .9em;
			margin-left: 1rem;
			@include media {
				margin-left: 0;
				margin-top: .5rem;
			}
		}

	.portlet-infobar {
		display: inline-flex;
		flex-flow: row wrap;
		justify-content: flex-start;

	 	* + .infobar-title {
			margin-top: 1.5rem;
		}

		// .infobar-section {}

			.section-data {
				line-height: 1.2em;
			}

		&.horizontal {
			justify-content: space-between;

			// .infobar-title {
			// 	margin-bottom: 1.5rem;
			// }

			.col > .infobar-section {
				width: 100%;
				display: flex;
			}
			
			.infobar-section {
				width: calc(50% - 1.25rem);
				padding: .5rem 0;
				display: flex;

				+ .infobar-section {
					margin-left: 0;
					margin-top: .5rem;
				}
			}

				.section-title {
					@include font-regular;
					width: 50%;
					text-transform: none;
					font-size: 1.4rem;
					padding-right: 1rem;
					margin-right: 1rem;
					margin-bottom: 0;
					max-width: 17.3rem;
				}

				.section-data {
					// width: 50%;
					flex: 1 0 0;

					&.full {
						width: 100%;
					}

					.data-label {
						margin-left: 1rem;
					}
				}
		}

		&:not(.horizontal) {
			.infobar-section {
				@include media {
					max-width: 50%;
					margin-bottom: 1rem;
				}
			}
			.infobar-title {
				margin-bottom: 1rem;
			}
		}

		.infobar-title {
			width: 100%;
			text-transform: uppercase;
			font-size: 1.1rem;
			margin-bottom: 0rem;
			display: block;
		}

		.col > .infobar-section {
			max-width: none;
			display: block;
			margin-right: 0;
		}

		.infobar-section {
			display: inline-block;
			font-size: 1.4rem;
			margin-right: 2.5rem;
		}

			.section-title {
				display: block;
				font-size: 1.1rem;
				text-transform: uppercase;
				color: $color-text-light;
				margin-bottom: 1rem;
			}

			.section-data {
				&.big {
					font-size: 4rem;
				}

				> * + * {
					margin-top: .5em;
				}
			}
	}

	.portlet-section{
		//@include clearfix;
		width: calc(100% + 4rem);
		margin: 0 -2rem;
		padding: 2.0rem 2rem;
		transition: background-color $transition-hover ease;

		&.collapser {
			padding: 0;
			border: none;
			border-radius: 0;

			.collapser-btn {
				padding: 2rem 2rem;
				text-transform: uppercase;
			}

			.collapser-innerwrap {
				padding: 0 2rem 2rem;
			}
		}

		&.loader-container:not(.low) {
			min-height: 8rem;
		}

		> .portlet-infobar {
			display: flex;
		}

		// Positions

		&.top{
			margin-top: -2.0rem;
			border-top-left-radius: $radius-big;
			border-top-right-radius: $radius-big;
			
			> .loading-indicator {
				border-top-left-radius: $radius-big;
				border-top-right-radius: $radius-big;
			}
		}

		&.bottom{
			margin-bottom: -2.0rem;
			border-bottom-left-radius: $radius-big;
			border-bottom-right-radius: $radius-big;

			> .loading-indicator {
				border-bottom-left-radius: $radius-big;
				border-bottom-right-radius: $radius-big;
			}
		}

		// Content Alignment

		&.align-right {
			text-align: right;
		}

		// Colors

		&.gray{
			background-color: $color-bg-light;
		}

		&.white{
			background-color: $color-white;
		}

		&.yellow{
			background-color: $color-yellow-bg;
			color: $color-yellow-text;
		}

		&.yellow-light{
			background-color: $color-yellow-bg-light;
			color: $color-yellow-text;
		}

		// Borders

		&.border-top{
			border-top: .1rem solid $color-gray;	
		}

		&.border-bottom{
			border-bottom: .1rem solid $color-gray;	
		}

		// Gaps

		&.gap-top-none{
			padding-top: 0;
		}

		&.gap-bottom-none{
			padding-bottom: 0;
		}
	}

	.portlet-filters {
		background-color: $color-bg;
		padding: 2rem;
		width: calc(100% + 4rem);
		margin: 2rem -2rem 0;
		display: flex;
		flex-flow: row wrap;

		@include media {
			justify-content: flex-end;
		}

		.filters-searchwrap {
			flex: 1 0 0;
			position: relative;

			+ * {
				margin-left: 1rem;

				@include media {
					margin: 1rem 0 0;
				}
			}

			@include media {
				width: 100%;
				flex: none;
			}
		}

			.searchwrap-submit {
				@include font-medium;
				position: absolute;
				right: .3rem;;
				top: .3rem;
				height: 3rem;
				padding: 0 1.2rem;
				z-index: 3;
				display: block;
				font-size: 1.4rem;
				color: $color-text-light;
				transition: color $transition-hover ease;
				background-color: $color-white;


				@include mediaMin {
					&:hover {
						color: lighten($color-text, $darken-ratio);
					}
				}

				&:active {
					color: $color-text;
				}

				i {
					margin-right: .5rem;
				}
			}

		.filters-more {
			display: none;
			width: 100%;
			padding-top: 2rem;

			&.show {
				display: block;
			}
		}

			.more-controls {
				text-align: right;

				> * {
					vertical-align: middle;
				}

				> * + * {
					margin-left: 1.5rem;
				}
			}
	}

	.table-container{
		width: calc(100% + 4.0rem);
		margin-left: -2.0rem;
		margin-right: -2.0rem;
	}

	.portlet-subtitle{
		@include font-regular;
		display: inline-block;
		line-height: 1em;
		font-size: 2.0rem;
		margin: .5rem 0 .7rem;
		margin-left: 3.4rem;
	}

	.portlet-controls{
		text-align: right;
		font-size: 0;
		letter-spacing: 0;

		&.left{
			float: left;
			text-align: left;
		}

		&.block{
			width: 100%;
			// width: auto;
		}

		&.border-top{
			padding-top: 2rem;
			border-top: .1rem solid $color-gray;	
		}

		> *{
			margin-right: 1.0rem;
			&:last-child{
				margin-right: 0;
			}
		}
	}

	.portlet-list{
		font-size: 1.4rem;
		line-height: 2.0rem;
		padding: .5rem 0;
	}

	> .tabs-container {
		width: calc(100% + 2rem);
		margin: 0 -1rem;
		
		&:first-child {
			margin-top: -2rem;
		}
		
		&:last-child {
			margin-bottom: -2rem;
		}
		
		.tabs-labels {
			margin: 0 -1rem;
			width: calc(100% + 2rem);
		}
		
		.items-tab {
			padding: 2rem 1rem;
			
			> .portlet-infobar {
				display: flex;
			}
		}

		@include media {
			margin: 0 -2rem;
			width: calc(100% + 4rem);

			.tabs-labels {
				margin: 0;
				width: 100%;
			}

			.labels-innerwrap {
				margin: 0;
				padding: 0 2rem;
			}

			.items-tab {
				padding: 2rem;
			}
		}
	}
}