.dropdown {
	display: inline-block;
	position: relative;

	&.open {
		.dropdown-content {
			display: block;
		}
	}

	.dropdown-content {
		display: none;
		position: absolute;
		top: 100%;
		right: 0;
		padding-top: .5rem;
		z-index: 4;
		min-width: calc(100% + 2rem);
		text-align: left;
	}

	.dropdown-innerwrap {
		border-radius: $radius-general;
		box-shadow: $shadow-general;
		
		> * {
			display: block;
			width: 100%;
			background-color: $color-white;
			border: .1rem solid $color-gray;
			padding: .7rem 1rem;
			font-size: 1.4rem;
			transition: background-color $transition-hover ease;
			white-space: nowrap;

			+ * {
				border-top: none;
			}

			&:disabled {
				opacity: .5;
			}

			&:first-child {
				border-top-left-radius: $radius-general;
				border-top-right-radius: $radius-general;
			}

			&:last-child {
				border-bottom-left-radius: $radius-general;
				border-bottom-right-radius: $radius-general;
			}

			@include mediaMin {
				&:not(:disabled) {
					&:hover {
						background-color: $color-bg;
					}
				}
			}

			&:active {
				background-color: $color-gray-light;
			}

			&.error {
				color: $color-error;
				background-color: $color-error-bg;

				@include mediaMin {
					&:hover {
						background-color: darken($color-error-bg, $darken-ratio-low);
					}
				}

				&:active {
					background-color: darken($color-error-bg, $darken-ratio);
				}
			}

			&.blue {
				color: $color-blue-light;
				background-color: lighten($color-blue-light, 53%);

				@include mediaMin {
					&:hover {
						background-color: darken(lighten($color-blue-light, 53%), $darken-ratio-low);
					}
				}

				&:active {
					background-color: darken(lighten($color-blue-light, 53%), $darken-ratio);
				}
			}
		}
	}
}