.section.payment-summary {
	flex: 1 0 0;
	display: flex;
	flex-direction: column;

	.summary-status {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $color-bg;

		&.status-success {
			color: $color-success-text;
			background-color: $color-success-bg;
		}

		&.status-canceled {
			background-color: $color-error-bg;
			color: $color-error-text;
		}

		&.status-ongoing {
			background-color: $color-yellow-bg;
			color: $color-yellow-text;
		}
	}

		.text-title {
			@include font-bold;
			letter-spacing: .01em;
			text-transform: uppercase;
			font-size: 1.1rem;
			margin-bottom: .8rem;
		}

	.summary-links {

	}

		.links-url {
			margin-bottom: 2rem;
			display: flex;
			flex-flow: row wrap;
		}

			.url-input {
				border: .1rem solid $color-gray;
				border-radius: $radius-general;
				box-shadow: $shadow-input;
				flex: 1 0 0;
				font-size: 1.2rem;
				line-height: 1.6rem;
				padding: 1rem;

				@include media {
					width: 100%;
					margin-bottom: 1rem;
					flex: none;
				}
			}

			.url-cta {
				width: 12rem;
				margin-left: 1rem;

				@include media {
					width: 50%;
					margin-left: 0;
					flex: none;

					+ .url-cta {
						margin-left: 1rem;
					}
				}

				&.link {
					width: 13.6rem;
				}
			}

		.links-qr {
			padding: 1.7rem 2rem;
			display: flex;
			border: .1rem solid $color-gray;
			box-shadow: $shadow-general;
			flex-flow: row wrap;
			align-items: center;
			border-radius: $radius-general;

			+ .links-buttons {
				margin-top: 2.8rem;
			}
		}

			.qr-image {
				width: 11.6rem;
				background-color: $color-text;
				margin-right: 2.7rem;
			}

			.qr-info {
				flex: 1 0 0;
				font-size: 1.1rem;
				line-height: 1.6rem;
			}

				.info-title {
					@include font-bold;
					font-size: 1.4rem;
				}

		.links-buttons {
			.btn + .btn {
				margin-left: .9rem;
			}
		}

			.buttons-title {
				display: block;
				margin-bottom: 1.6rem;
			}
}