.order-status {
	font-size: 1.2rem;
	display: inline-flex;
	// flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;
	white-space: nowrap;

	&.success {
		&:not(.label) {
			color: $color-success;
		}

		.status-icon {
			@include icon('success', 'before');
		}
	}
	&.open {
		&:not(.label) {
			color: $color-yellow;
		}

		.status-icon {
			@include icon('warning', 'before');
		}
	}
	&.canceled {
		&:not(.label) {
			color: $color-red;
		}
		&.label {
			background-color: $color-error;
		}

		.status-icon {
			@include icon('alert', 'before');
		}
	}
	&.ongoing {
		&:not(.label) {
			color: $color-text;
		}
		&.label {
			background-color: $color-yellow-dark;
		}

		.status-icon {
			@include icon('warning', 'before');
		}
	}

	.status-icon {
		margin-right: .5rem;
	}
}