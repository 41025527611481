// Credit Card Info Fields
.cardinfo {
    align-items: center!important;

    .cardinfo-cardnumber {
        .input-innerwrap {
            position: relative;
        }
    }

        .cardnumber-type {
            position: absolute;
            right: 1.5rem;
            top: 1rem;
            bottom: 1rem;
            width: 2.6rem;

            .type-image {
                width: 100%;
                height: 100%;
            }
        }
}

// Input for Credit Cards
.cards-select {
    &.error {
        .checkwrap {
            input:disabled + label {
                background-color: rgba($color-error-bg, .5)!important;
            }
        }
    }

    .checkwrap {
        input {
            &:disabled {
                + label {
                    .label-content {
                        opacity: 1;

                        > *:not(.card-controls) {
                            opacity: .5;
                        }
                    }
                }
            }
        }
    }
}

// Credit Card Deet
.cardbar {
    align-items: center!important;
    
    .label-content, &.list-card {
        display: flex;
        align-items: center;
    }

    .cardbar-type {
        width: 3rem;
        height: 3rem;
        margin: -.6rem 2.4rem -.6rem 0;

        .type-image {
            width: 100%;
            height: 100%;
        }
    }

    .cardbar-label {
        flex: 1 0 0;
        margin-right: 2rem;
    }

        .label-currency {
            color: $color-text-light;
            font-size: .9em;
            margin-left: 1rem;
        }

        .label-autopay {
            color: $color-blue;
            font-size: .9em;
            margin-left: 1.2rem;
            display: inline-flex;
            align-items: center;

            i {
                background-color: $color-blue;
                width: 1.8rem;
                height: 1.8rem;
                color: $color-white;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-right: .4rem;
                font-size: .8rem;
            }
        }

    .cardbar-info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cardbar-controls {
        margin-left: 2rem;

        > * + * {
            margin-left: 1em;
        }
    }
}