.page.authentication {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: $color-blue;
	background-image: url('/assets/images/shared/page-decoration.svg');
	background-repeat: no-repeat;
	background-position: bottom 10% left 0;
	background-size: 15.1rem;

}

.section {
	&.authentication {
		width: 50rem;

		.authentication-logo {
			display: block;
			width: 25rem;
			height: 8rem;
			margin: 0 auto 4rem;
		}

		.authentication-companyinfo {
			display: flex;
			align-items: center;
			flex-flow: row wrap;
			margin-bottom: 4rem;
		}

			.companyinfo-avatarwrap {
				width: 10rem;
				height: 10rem;
				border-radius: 50%;
				background-image: url('/assets/images/admin/avatar-default.svg');
				background-size: cover;
				overflow: hidden;
			}

			.companyinfo-avatar {
				width: 100%;
				height: 100%;
			}

			.companyinfo-title {
				font-size: 3rem;
				margin-left: 2rem;
				color: $color-white;
			}

		.authentication-bottomlogo {
			width: 10rem;
			margin: 3rem auto 0;
		}
	}
}
