.loader-container {
	position: relative;
	min-height: 15rem;
	z-index: 1;

	> .loading-indicator {
		position: absolute;
	}

	&.low {
		min-height: 4rem;
	}
}

.loading-indicator {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba($color-white, .9);
	color: $color-text;
	z-index: 999;
	opacity: 0;
	transition: opacity 400ms ease;

	&.inner {
		position: absolute;
	}

	&.static {
		position: relative;
	}

	&.strict {
		background-color: $color-white;
	}

	&.dark {
		background-color: $color-bg;
	}

	&.error {
		background-color: $color-error-bg;
		color: $color-error-text;
	}

	&.oversize {
		.indicator-wrapper {
			min-height: 50rem;
		}
	}

	.indicator-message {
	}

	.indicator-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.indicator-message {
		font-size: 1.2rem;
		margin-bottom: 8rem;
	}

	i {
		@include spin;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		font-size: 3rem;
	}

	/*&.in-page{
		z-index: 9;
		background-color: $color-ice;

		i{
			display: none;
			color: $color-gray;
		}
	}*/

	&.show {
		opacity: 1;
	}
}