.price-conversion {

	&.conversion-info {
		font-size: 1.2rem;
		line-height: 1.5rem;
		display: inline-block;
	}

	.label {
		+ .pricebox {
			font-size: 1.2rem;
			margin-left: .5rem;
			color: $color-text-light;
		}
	}

	.pricebox {
		display: inline-block;
		vertical-align: bottom;
	}
}
