.modal-create-or-update-order {
	.modal-header {
		.order-clientselect {
			margin-top: 2rem;
		}
	}

	.order-clientselect {
		+ .grid-container {
			margin-top: 2rem;
		}

		@include media {
			justify-content: flex-end;
		}
	}

		.clientselect-form {
			display: flex;
			align-items: center;
			flex-flow: row wrap;
		}

			.form-select {
				flex: 1 0 0;

				@include media {
					width: 100%;
					flex: none;
				}
			}

			.form-controls {
				display: flex;
				align-items: center;
				margin-left: 1.5rem;

				@include media {
					width: 20rem;
					max-width: 100%;
					margin-top: 1.5rem;
				}
			}

				.controls-seperator {
					margin-right: 1.5rem;
				}

				.controls-newbtn {
					flex: 1 0 0;
				}

		.clientselect-erpbtn {
			margin-top: 1.5rem;
		}

	.order-message {
		margin-bottom: 3rem;
	}

	.order-clientinfo {
		@include font-bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: .1rem solid $color-text;
		padding: 0 0 1rem;
		margin-bottom: 3rem;

		+ .order-group {
			margin-top: 3rem;
		}
	}

		.clientinfo-cta {
			min-width: 21rem;
		}

	.order-conversionnote {
		color: $color-orange-dark;
		font-size: 1.2rem;
		margin-top: 1rem;
	}

	.order-installments {
		.installments-title {
			font-size: 1.4rem;
			margin-bottom: 1rem;
			color: $color-blue;
		}

		.installments-bank {
			display: block;
			color: $color-blue-dark;
			margin-bottom: 1rem;
		}

		// .installments-opts {
		// }
		
			.opts-opt {
				.label-content {
					display: flex;
					align-items: flex-start;
				}

				.opt-text {
					flex: 1 0 0;
				}

				.opt-description {
					display: block;
					font-size: 1.2rem;
					margin-top: .2rem;
				}

				.opt-count {
					color: $color-text-light;
					text-align: right;
				}

				.opt-price {
					margin-left: 3.3rem;
					text-align: right;

					.pricebox + * {
						margin-top: .2rem;
					}
					// color: $color-blue-dark;
				}
			}
	}

	// .order-plan {
	// }

		.plan-interval {
			width: 80%;
		}

			.interval-inputwrap {
				display: flex;
				align-items: center;
			}

			.interval-range {
				width: 7rem;
				margin: 0 1rem;
			}

			.interval-period {
				flex: 1 0 0;
			}

		.plan-notifications {
			width: 50%;
		}

		.plan-installments {
			width: 90%;
		}

			.installments-inputwrap {
				display: flex;
				align-items: center;
			}

			.installments-count {
				margin: 0 1rem;
			}
}