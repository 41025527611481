.tabs-container {
	@include media {
		overflow: hidden;
	}

	min-height: 8rem;
	
	.tabs-labels {
		border-bottom: .1rem solid $color-gray;

		@include media {
			white-space: nowrap;
			overflow: auto;
			width: calc(100% + 2rem);
			margin: 0 -1rem;
		}
	}

		.labels-innerwrap {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: flex-start;
			z-index: 1;
			padding: 0 1rem;
			margin: 0 1rem;

			@include media {
				display:inline-block;
				white-space: nowrap;
				width: auto;
			}

			&:before {
				@include pseudo;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 1px;
				//background-color: rgba($color-text, .1);
				z-index: -1;

				@include media {
					bottom: .1rem;
				}
			}
		}

		.labels-item {
			@include media {
				display: inline-block;
			}

			+ .labels-item {
				margin-left: 3.5rem;
			}

			// @include media {
			// 	padding: 0;
			// 	display: inline-block;

			// 	+ .labels-item {
			// 		margin-left: 4rem;
			// 	}
			// }
		}

		.item-btn {
			@include font-bold;
			color: $color-text-light;
			text-transform: uppercase;
			cursor: pointer;
			font-size: 1.2rem;
			letter-spacing: .01em;
			line-height: 2rem;
			padding: 2.8rem 0 1.3rem;
			text-align: left;
			transition: color $transition-hover ease;
			position: relative;
			z-index: 3;
			display: inline-block;
			transform: translate3d(0, 0, 0);		

			&:before {
				@include pseudo;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 0.5rem;
				background-color: $color-green;
				transform: scaleX(0) translate3d(0, 0, 0);
				opacity: 0;
				transition: transform $transition-anim ease, opacity $transition-anim ease;
				transform-origin: top center;
				z-index: 3;
				border: none;

				// @include media {
				// 	height: .3rem;
				// 	border-radius: .2rem;
				// }
			}

			&.active {
				color: $color-text;
				&:before {
					opacity: 1;
					transform: scaleX(1) translate3d(0, 0, 0);;
				}
			}

			.btn-icon {
				color: $color-text-light;
				margin-right: .4rem;
				font-size: 1.2rem;
			}
			
			.btn-badge {
				background-color: $color-gray;
				color: $color-text;
				border-radius: $radius-general;
				padding: 0 .7rem;
				display: inline-block;
				margin-left: 1rem;
				
				@include labelColors;
			}
		}


	.tabs-items {
		padding: 0;

		// @include media {
		// 	padding: 4.5rem 0;
		// }
	}
	
		.items-tab {
			opacity: 0;
			padding: 2rem 0 0;
			transition: opacity 350ms ease;
			display: none;

			&.active {
				display: block;
			}

			&.show {
				opacity: 1;
			}
		}
}